import { useState, useEffect } from "react";
import styled from "styled-components";
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-scroll";

//import Latphrao from '../../assets/img/store/'
import Implerial from '../../assets/img/store/implerial.jpg'
import Planetarium from '../../assets/img/store/planetarium.jpg'

import '../../assets/css/AgentShop.css'


export default function Modals({icon, id, plainText}) {
    const [show, setShow] = useState(false);
    const [header, setHeader] = useState();
    const [content, setContent] = useState();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let getContent;
    const imgSize = {
        width: 'auto',
        height: '266px',
    }
    
    const imgResize = {
        width: '336px',
        height: '266px',
        overflow: 'hidden',
        textAlign: 'center',
    }

    useEffect(() => {
        switch (icon) {
            case "store":
                // getHeader = 'หน้าร้านสาขา'
                setHeader('หน้าร้านสาขา');
                getContent = 
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <h5>ร้านศึกษาภัณฑ์พาณิชย์สาขาอิมพีเรียลเวิลด์สำโรง (ชั้น 4)</h5>
                            <div style={imgResize}>
                                <img src='img/store/implerial.jpg' style={imgSize}/>
                            </div>
                            <p>
                                <div>
                                    <strong>ที่อยู่:</strong> ชั้น 4  อิมพีเรียลเวิลด์สำโรง 999 หมู่ 1  ถนนสุขุมวิท  ต.สำโรงเหนือ  อ.เมือง  จ.สมุทรปราการ   10270
                                </div>
                                <div><strong>โทร:</strong> 0 2087 8631-2</div>
                                <div>
                                    <strong>วัน-เวลาทำการ:</strong>  วันจันทร์ - วันอาทิตย์  11.00 น. - 19.00 น.
                                </div>
                            </p>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <h5>ร้านศึกษาภัณฑ์พาณิชย์สาขาท้องฟ้าจำลอง</h5>
                            <div style={imgResize}>
                                <img src='img/store/planetarium.jpg' style={imgSize}/>
                            </div>
                            <p>
                                <div>
                                    <strong>ที่อยู่:</strong> 936  ถนนสุขุมวิท  แขวงพระโขนง  เขตคลองเตย  กทม. 10110 อยู่ระหว่าง บริษัทขนส่ง จำกัด (เอกมัย) กับท้องฟ้าจำลอง
                                </div>
                                <div><strong>โทร:</strong>0-2382-2874</div>
                                <div>
                                    <strong>วัน-เวลาทำการ:</strong> วันจันทร์ - วันศุกร์ 09.00 น. - 17.00 น.
                                </div>
                            </p>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <h5>ร้านศึกษาภัณฑ์พาณิชย์สาขาลาดพร้าว</h5>
                            <div style={imgResize}>
                                <img src='img/store/latphrao.JPG' style={imgSize}/>
                            </div>
                            <p>
                                <div>
                                    <strong>ที่อยู่:</strong> 2249  ถนนลาดพร้าว  แขวงสะพานสอง  เขตวังทองหลาง  กทม.  10310 อยู่ระหว่างซอยลาดพร้าว 61-63
                                </div>
                                <div><strong>โทร:</strong>0 2514 4023-30 ต่อ 227</div>
                                <div>
                                    <strong>วัน-เวลาทำการ:</strong> วันจันทร์ - วันศุกร์ 09.00 น. - 17.00 น.
                                </div>
                            </p>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <h5>ร้านศึกษาภัณฑ์พาณิชย์สตรีท (เดอะฮับ ชั้น3 เซียร์รังสิต )</h5>
                            <div style={imgResize}>
                                <img src='img/store/street.jpg' style={imgSize}/>
                            </div>
                            <p>
                                <div>
                                    <strong>ที่อยู่:</strong>99.99/4 หมู่ 8  ถนน พหลโยธิน คูคต ลำลูกกา ปทุมธานี 12130 เดอะฮับ ชั้น3 เซียร์รังสิต
                                </div>
                                <div><strong>โทร:</strong>0 2054 1117,0 2221 7474</div>
                                <div>
                                    <strong>วัน-เวลาทำการ:</strong> วันจันทร์ - วันอาทิตย์   11.00 น. - 19.00 น.
                                </div>
                            </p>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <h5>ร้านศึกษาภัณฑ์พาณิชย์สาขาราชบพิธ</h5>
                            <div style={imgResize}>
                                <img src='img/store/ratchabophit.JPG' style={imgSize}/>
                            </div>
                            <p>
                                <div>
                                    <strong>ที่อยู่:</strong>9  ถนนราชบพิธ  แขวงวัดราชบพิธ  เขตพระนคร  กทม. 10200  ตรงกันข้ามกับสมาคมหอการค้าไทย
                                </div>
                                <div><strong>โทร:</strong>0 2622 2426-27</div>
                                <div>
                                    <strong>วัน-เวลาทำการ:</strong> วันจันทร์ - วันศุกร์ 09.00 น. - 17.00 น.
                                </div>
                            </p>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <h5>ร้านศึกษาภัณฑ์พาณิชย์สาขาอ้อมน้อย</h5>
                            <div style={imgResize}>
                                <img src='img/store/omnoi.JPG' style={imgSize}/>
                            </div>
                            <p>
                                <div>
                                    <strong>ที่อยู่:</strong>202 หมู่ 12 ซอยเพชรเกษม 87  ถนนเพชรเกษม  ต.อ้อมน้อย  อ.กระทุ่มแบน  จ.สมุทรสาคร  74130 ปากซอยเพชรเกษม 87  หรือตรงข้ามกับโรงเรียนวัดศรีสำราญ
                                </div>
                                <div><strong>โทร:</strong>0 2420 7095-8</div>
                                <div>
                                    <strong>วัน-เวลาทำการ:</strong> วันจันทร์ - วันเสาร์ 09.00 น. - 17.00 น.
                                </div>
                            </p>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <h5>ร้านศึกษาภัณฑ์พาณิชย์สาขาคุรุสภา</h5>
                            <div style={imgResize}>
                                <img src='img/store/kurusapha.jpg' style={imgSize}/>
                            </div>
                            <p>
                                <div>
                                    <strong>ที่อยู่:</strong>128/1 ถนน นครราชสีมา แขวงดุสิต เขตดุสิต กทม. 10300 อยู่เยื้องกับหอประชุมคุรุสภา ภายในกระทรวงศึกษาธิการ
                                </div>
                                <div><strong>โทร:</strong>0 2281 1637   0 2281 4657</div>
                                <div>
                                    <strong>วัน-เวลาทำการ:</strong> วันจันทร์ - วันศุกร์  08.00 น. - 16.00 น.
                                </div>
                            </p>
                        </div>
                    </div>
                setContent(getContent)
                break;
            case "instead":
                // getHeader = 'หน้าร้านสาขา'
                setHeader('ร้านค้าตัวแทน');
                getContent = 
                <div style={{width:'100%'}}>
                    <div className="tb-content tb1">
                        {/* <!--<h3>กรุงเทพฯ ปริมณฑล ภาคกลางและตะวันออก</h3>--> */}
                        <div className="tb-inner-content">
                            <table width="100%">
                                <thead>
                                <tr>
                                    <th width="40">ที่</th>
                                    <th>รายชื่อ</th>
                                    <th width="100">จังหวัด</th>
                                    <th>ที่อยู่</th>
                                    <th width="120">โทรศัพท์</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>บริษัท สุพรรณบุ๊คสเตชั่นเนอรี่ จำกัด</td>
                                    <td>กรุงเทพมหานคร</td>
                                    <td><a href="https://goo.gl/maps/81vQ8S82Gb6YJA7M9" target="_blank">352 ถ.บรมราชชนนี แขวงบางบำหรุ เขตบางพลัด จ.กรุงเทพมหานคร</a></td>
                                    <td>&nbsp;<a href="tel:0918941544">091 894 1544</a></td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>ห้างหุ้นส่วนจำกัด เบญจวิโรฒ</td>
                                    <td>นนทบุรี</td>
                                    <td><a href="https://goo.gl/maps/hfexjh2PEV3ccsAT9" target="_blank">3/25-26 ถ.พิบูลสงคราม ต.สวนใหญ่ อ.เมือง จ.นนทบุรี</a></td>
                                    <td><a href="tel: 029666184">&nbsp;02 966 6184</a></td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>บริษัท ดีจริงศึกษาภัณฑ์ (รังสิต) จำกัด</td>
                                    <td>ปทุมธานี</td>
                                    <td><a href="https://goo.gl/maps/cf6TQqNJyYXrc57j6" target="_blank">99-99/1 ถ.รังสิต-ปทุมธานี ต.ประชาธิปัตย์ อ.ธัญบุรี จ.ปทุมธานี</a></td>
                                    <td><a href="tel: 029590550">&nbsp;02 959 0550</a></td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>ห้างหุ้นส่วนจำกัด ชลบุรี ว. พานิช</td>
                                    <td>ชลบุรี</td>
                                    <td><a href="https://goo.gl/maps/kiAWibadXdeJtBr68" target="_blank">695/47-50 ถ.สุรชัย ต.มะขามหย่ง อ.เมือง จ.ชลบุรี</a></td>
                                    <td><a href="tel:0816302426">&nbsp;081 630 2426</a></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="tb-content tb2">
                        {/* <!--<h3>ภาคเหนือ</h3>--> */}
                        <div className="tb-inner-content">
                            <table width="100%">
                                <thead>
                                <tr>
                                    <th width="40">ที่</th>
                                    <th>รายชื่อ</th>
                                    <th width="100">จังหวัด</th>
                                    <th>ที่อยู่</th>
                                    <th width="120">โทรศัพท์</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>บริษัท มิวนิคบุ๊คเซ็นเตอร์ จำกัด</td>
                                    <td>&nbsp; เชียงราย</td>
                                    <td><a href="https://goo.gl/maps/YFfbgsnQZert1F8W9" target="_blank">630 ถ.สิงหไคล ต.เวียง อ.เมืองเชียงราย จ.เชียงราย</a></td>
                                    <td><a href="tel:053601525">&nbsp;053 601 525</a> <br/> <a href="tel:0864297370">086 429 7370</a></td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>บริษัท โมเดิร์น เอสบี พลัส จำกัด</td>
                                    <td>&nbsp; เชียงใหม่</td>
                                    <td><a href="https://goo.gl/maps/Erx5uT8gVGeFTku57" target="_blank">392/10 หมู่ที่ 3 ตำบลบ้านกลาง อำเภอสันป่าตอง จ.เชียงใหม่</a></td>
                                    <td>&nbsp;<a href="tel:0535105567">052 135 055</a></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="tb-content tb3">
                        {/* <!--<h3>ภาคตะวันออกเฉียงเหนือ</h3>--> */}
                        <div className="tb-inner-content">
                            <table width="100%">
                                <thead>
                                <tr>
                                    <th width="40">ที่</th>
                                    <th>รายชื่อ</th>
                                    <th width="100">จังหวัด</th>
                                    <th>ที่อยู่</th>
                                    <th width="120">โทรศัพท์</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td width="43">1</td>
                                    <td width="277">บริษัท ทรัพย์สิริศึกษา จำกัด</td>
                                    <td width="86">อุดรธานี</td>
                                    <td width="395"><a href="https://goo.gl/maps/zdxxTSeJvwZoNs8MA" target="_blank">233/9 ถ.ซอยบ้านโนนยาง ต.หมากแข้ง อ.เมือง จ.อุดรธานี</a></td>
                                    <td width="115"><a href="tel:042329140">&nbsp;042 329 140</a></td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>ห้างหุ้นส่วนจำกัด ตกเซ้งเครื่องเขียน</td>
                                    <td>กาฬสินธุ์</td>
                                    <td><a href="https://goo.gl/maps/JqbKbrtzqT9qboDT8" target="_blank">7/10,7/16 ถ.ทุ่งศรีเมือง ต.กาฬสินธุ์ อ.เมืองกาฬสินธุ์ จ.กาฬสินธุ์</a></td>
                                    <td><a href="tel:0960454559">&nbsp;096 045 4559</a></td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>ห้างหุ้นส่วนจำกัด ศึกษาภัณฑ์กาฬสินธุ์</td>
                                    <td>กาฬสินธุ์</td>
                                    <td><a href="https://goo.gl/maps/STUpgvNjPsUHFHvC9" target="_blank">20 ถ.ชัยสุนทร ต.กาฬสินธุ์ อ.เมืองกาฬสินธุ์ จ.กาฬสินธุ์</a></td>
                                    <td>&nbsp;<a href="tel:0818716388">081 871 6388</a></td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>บริษัท บิ๊กบุ๊ค101 จำกัด</td>
                                    <td>ร้อยเอ็ด</td>
                                    <td><a href="https://goo.gl/maps/vGFJHpp78zzx1bxr7" target="_blank">53/1 ถ.ราชการดำเนิน ต.ในเมือง อ.เมืองร้อยเอ็ด จ.ร้อยเอ็ด</a></td>
                                    <td>&nbsp;<a href="tel:0828401111">082 840 1111</a></td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>บริษัท รวมวิทยา จำกัด</td>
                                    <td>นครราชสีมา</td>
                                    <td><a href="https://goo.gl/maps/cmKjgSbUciic9uQu9" target="_blank">275,275/1,275/2,277,279 ถ.ยมราช ต.ในเมือง อ.เมือง จ.นครราชสีมา</a></td>
                                    <td>&nbsp;<a href="tel:044259604">044 259 604 ต่อ 7</a></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="tb-content tb4">
                        {/* <!--<h3>ภาคใต้</h3>--> */}
                        <div className="tb-inner-content">
                            <table width="100%">
                                <thead>
                                <tr>
                                    <th width="40">ที่</th>
                                    <th>รายชื่อ</th>
                                    <th width="100">จังหวัด</th>
                                    <th>ที่อยู่</th>
                                    <th width="120">โทรศัพท์</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>ห้างหุ้นส่วนจำกัด ปรินทรบุ๊คเซ็นเตอร์</td>
                                    <td>สงขลา</td>
                                    <td>652/39 หมู่ 2 ต.พะวง อ.เมือง จ.สงขลา</td>
                                    <td>&nbsp;<a href="tel:0817380695">081 738 0695</a></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                setContent(getContent)
                break;
            case "contact":
                //getHeader = 'ติดต่อเจ้าหน้าที่การตลาด'
                setHeader('ติดต่อเจ้าหน้าที่การตลาด')
                break;
            default:
                //getIcon = <RollerIcon />;
                setHeader('Default')
                setContent('getContent')
                break;
        }    
    },[]);

    return(
        <>
            <Link to="/" onClick={handleShow}>{plainText}</Link>
            <Modal show={show} size="xl" onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        {content}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    
                </Modal.Footer>
            </Modal>
        </>
    )
}