import React from "react";
import styled from "styled-components";
// Assets
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";

import SspMall from '../../assets/img/logo/sspmall.png';
import Lazada from '../../assets/img/logo/lazada.png';
import Shopee from '../../assets/img/logo/shopee-logo-40482.png';
import Store from '../../assets/img/logo/store.png'
import Demographic from '../../assets/img/logo/demographic.png'

export default function ServiceBox({icon, title, subtitle}) {
  let getIcon;

  switch (icon) {
    case "ssp":
      //getIcon = <RollerIcon />;
      getIcon = <img src={SspMall} style={{width:'50%'}} />
      break;
    case "lazada":
      //getIcon = <MonitorIcon />;
      getIcon = <img src={Lazada} style={{width:'30%'}} />
      break;
    case "shopee":
      //getIcon = <BrowserIcon />;
      getIcon = <img src={Shopee} style={{width:'30%'}} />
      break;
    case "store":
      getIcon = <img src={Store} style={{width:'28%'}}></img>
      break;
    case "instead":
      getIcon = <img src={Demographic} style={{width:'28%'}}></img>
      break;
    default:
      //getIcon = <RollerIcon />;
      getIcon = <img src={SspMall} style={{width:'80%'}} />
      break;
  }


  return (
    // <Wrapper className="flex flexColumn">
    //   <IconStyle>{getIcon}</IconStyle>
    //   <TitleStyle className="font20 extraBold">{title}</TitleStyle>
    //   <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    // </Wrapper>
    <WrapperBtn className="animate pointer" style={{width:'100%'}} onClick={() => null}>
        <Wrapper className="whiteBg radius8 shadow" style={{textAlign:'center'}}>
          <h3 className="font20 extraBold" style={{textAlign:'center'}}>{title}</h3>
          {getIcon}
          <p className="font13 extraBold">{}</p>
          {/* <div className="flex">
            <p className="tag coralBg radius6 font13 extraBold">{tag}</p>
          </div> */}
        </Wrapper>
      </WrapperBtn>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
  @media (max-width: 860px) {
    margin: 0 auto;
  }
  text-align:center
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
  text-align:center
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;

const WrapperBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  :hover {
    opacity: 0.5;
    color:red
  }
`;