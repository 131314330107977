import React, { useState } from "react";
import styled from "styled-components";

import Modal from 'react-bootstrap/Modal';

export default function ProjectBox({ img, title, text, action}) {
  const imgSize = {
    width: 'auto',
    height: '290px',
  }

  const imgResize = {
    width: '376px',
    height: '309px',
    overflow: 'hidden',
    textAlign: 'center',
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Wrapper>
        {/* <ImgBtn className="aniamte pointer" onClick={action ? () => action() : null}> */}
        <ImgBtn className="aniamte pointer" onClick={handleShow}>
          <div style={imgResize}>
            <img className="radius8" src={img} alt="project" style={imgSize}></img>
          </div>
          <h3 className="font20 extraBold">{title}</h3>
        </ImgBtn>
      </Wrapper>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12" style={{textAlign:'center'}}>
              <div>
                <img className="radius8" src={img} alt="project" style={{width: '60%'}}></img>
              </div>
            </div>
            
          </div>
          <div className="row">
            <div className="col-12" style={{textAlign:'center'}}>
              <hr/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p dangerouslySetInnerHTML={ {__html: text}}/>
            </div>
          </div>
          
        </Modal.Body>
        <Modal.Footer>
          
        </Modal.Footer>
      </Modal>
    </>
  );
}

const Wrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  img {
    width: 100%;
    height: auto;
    margin: 20px 0;
  }
  h3 {
    padding-bottom: 10px;
  }
`;
const ImgBtn = styled.button`
  background-color: transparent;
  border: 0px;
  outline: none;
  padding: 0px;
  margin: 0px;
  :hover > img {
    opacity: 0.5;
  }
`;