import React, { useState,useEffect } from "react";
import styled from "styled-components";
// Assets
import ContactImg1 from "../../assets/img/contact-1.png";
import ContactImg2 from "../../assets/img/contact-2.png";
import ContactImg3 from "../../assets/img/contact-3.png";

import Address from "../../assets/img/logo/location-pin.png";
import Tel from '../../assets/img/logo/old-typical-phone.png';
import Smartphone from '../../assets/img/logo/smartphone-call.png'
import Online from '../../assets/img/logo/online-chat.png';
import LineAddress from '../../assets/img/logo/linesuksapan.png';
import Line from '../../assets/img/logo/line.png'
import Facebook from '../../assets/img/logo/facebook.png';
import Instagram from '../../assets/img/logo/instagram.png';
import Tiktok from '../../assets/img/logo/tiktok.png';
import { Link } from "react-scroll";

import Modals from "../Elements/Modal";
import Modal from 'react-bootstrap/Modal';


export default function Contact() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Wrapper id="contact">
        {/* <div className="lightBg"> */}
        <div style={{backgroundColor:'#FFE9FD'}}>
          <div className="container whiteBg">
            <HeaderInfo>
              <h1 className="font40 extraBold">ติดต่อเรา</h1>
            </HeaderInfo>
            <div className="row" style={{ height:'600px', paddingTop:'10px'}}>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <iframe src="https://maps.google.com/maps?q=13.791123%2C100.600721&hl=thai&t=m&z=15&output=embed" className="map" style={{width:'100%', height:'90%'}}></iframe>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
                {/* <div style={{ width: "50%" }} className="flexNullCenter flexColumn">
                  <ContactImgBox>
                    <img src={ContactImg1} alt="office" className="radius6" />
                  </ContactImgBox>
                  <ContactImgBox>
                    <img src={ContactImg2} alt="office" className="radius6" />
                  </ContactImgBox>
                </div>
                <div style={{ width: "50%" }}>
                  <div style={{ marginTop: "100px" }}>
                    <img src={ContactImg3} alt="office" className="radius6" />
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-12">
                    <h5><img src={Address}/>ที่อยู่</h5>
                    <p style={{marginBottom:'20px'}}>2249 ถนนลาดพร้าว แขวงสะพานสอง เขตวังทองหลาง กรุงเทพฯ 10310</p>

                    <h5><img src={Tel}/>โทรศัพท์</h5>
                    <p style={{marginBottom:'20px'}}>02-514-4023-30 ต่อ 222,223</p>
                
                    {/* <h5 style={{marginBottom:'20px'}}>
                      <span className="semiBold pointer">
                        <img src={Smartphone}/>
                        <Modals plainText={'ติดต่อเจ้าหน้าที่การตลาด'} icon={'contact'}/>
                      </span>
                    </h5> */}

                    <h5><img src={Online}/>ติดตามข่าวสาร</h5>
                    <p>
                      <a href="https://lin.ee/hPg6w7c" target="_blank" style={{margin:'2px'}}>
                        <img src={Line}/> ศึกษาภัณฑ์พาณิชย์ (Line ID: @Suksapanpanit)
                      </a>
                    </p>
                    <p>
                      <a href="https://www.facebook.com/suksapanpanitcenter/" target="_blank" style={{margin:'2px'}}>
                        <img src={Facebook}/> suksapanpanitcenter
                      </a>
                    </p>
                    <p>
                      <a href="https://www.instagram.com/suksapan.official/" target="_blank" style={{margin:'2px'}}>
                        <img src={Instagram}/> suksapan.official
                      </a>
                    </p>
                    <p>
                      <a href="https://www.tiktok.com/@suksapan.official?lang=th-th" target="_blank" style={{margin:'2px'}}>
                        <img src={Tiktok}/> @suksapan.official
                      </a>
                    </p>
                    {/* <p>@Suksapanpanit<br/><img src={LineAddress} style={{width:'25%'}}/></p> */}

                    {/* <h3><img src={Instagram}/>Instagram</h3>
                    <p>02-514-4023-30 ต่อ 222,223</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>

      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          
        </Modal.Footer>
      </Modal> */}
      
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
  margin-top: 10px;
  border-radius: 50px;
`;
const HeaderInfo = styled.div`
  padding: 20px 0 0px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
  font-family: "Prompt-Medium";
  text-align: center;
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









