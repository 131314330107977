import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Modal from 'react-bootstrap/Modal';
import { Form } from "react-bootstrap";

import SaleBox from "./SaleBox";
import ImgAvatar from '../../assets/img/personal/img_avatar1.png'

import axios from "axios";

export default function BlogBox({ tag, title, img, action, author }) {
  const [show, setShow] = useState(false);
  const [salecontact, setSalecontact] = useState([])
  const [units, setUnits] = useState([]);
  const [headWholeSale, setHeadWholeSale] = useState([])
  const [wholeSale, setWholeSale] = useState([])

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function axiosData(url) {
    // create a promise for the axios request
    const promise = axios.get(url)

    // using .then, create a new promise which extracts the data
    const dataPromise = promise.then((response) => response.data)

    // return it
    return dataPromise
  }

  useEffect(() => {
    //console.log(Date.now())
    axiosData(`${window.location.origin}/data/DataSaleCenter.json`)
              .then(response => setSalecontact(response.data))
              .catch(err => console.log(err));

    axiosData(`${window.location.origin}/data/DataUnit.json`)
              .then(response => setUnits(response.unit))
              //.then(response => console.log(res))
              .catch(err => console.log(err))

    axiosData(`${window.location.origin}/data/DataSaleRegion.json`)
              .then(response => setHeadWholeSale(response.data))
              .catch(err => console.log(err));

    axiosData(`${window.location.origin}/data/DataSaleRegion.json`)
              .then(response => setWholeSale(response.data.filter(f => { return f.unit_id != 0})))
              .catch(err => console.log(err));
  },[])

  function handleChangeData(e){
    axiosData(`${window.location.origin}/data/DataSaleCenter.json`)
              .then(response => setSalecontact(response.data.filter(f => { return f.unit_id == e.target.value})))
              .catch(err => console.log(err))
  }

  function handleChangeDataRegion(e){
    axiosData(`${window.location.origin}/data/DataSaleRegion.json`)
              .then(response => setWholeSale(response.data.filter(f => { return f.unit_id == e.target.value})))
              .catch(err => console.log(err))
  }


  return (
    <>
      <WrapperBtn className="animate pointer" style={{width:'100%'}} onClick={handleShow}>
        <Wrapper className="whiteBg radius8 shadow" style={{textAlign:'center'}}>
          <h3 className="font20 extraBold" style={{textAlign:'center'}}>{title}</h3>
          <img src={img} style={{ width:'40%', textAlign:'center'}} />
          <p className="font13 extraBold">{author}</p>
          {/* <div className="flex">
            <p className="tag coralBg radius6 font13 extraBold">{tag}</p>
          </div> */}
        </Wrapper>
      </WrapperBtn>

      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {/* <img className="radius8" src={img} alt="project" style={{width: '60%'}}></img> */}
            { tag == 1 
              ?
                <>
                  <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                    เลือกสาขา
                  </div>
                  <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                    <Form.Select aria-label="Default select example" onChange={handleChangeData}>
                      <option>เลือกสาขา</option>
                      {
                        units.map((m) => 
                          <option key={m.unit_id} value={m.unit_id}>{m.unit_name}</option>
                        )
                      }
                    </Form.Select>
                  </div>
                </>
              : 
                <>
                  <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                    เลือกภูมิภาค
                  </div>
                  <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                    <Form.Select aria-label="Default select example" onChange={handleChangeDataRegion}>
                      <option>เลือกภูมิภาค</option>
                      <option value="1">ภาคเหนือ</option>
                      <option value="2">ภาคตะวันออกเฉียงเหนือ</option>
                      <option value="3">ภาคกลางและตะวันออก</option>
                      <option value="4">ภาคใต้</option>
                      <option value="5">แบบพิมพ์</option>
                    </Form.Select>
                  </div>
                </>
            }
          </div>
          <div className="row">
            <div className="col-12" style={{textAlign:'center'}}>
              <hr/>
            </div>
          </div>
          <div className="row justify-content-center" style={{ width: 'auto'}}>
            { tag == 1 
              ?
                // salecontact
                // .map((sale) => (
                //   <div key={sale.id} className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                //     {/* <span>{sale.id}</span> */}
                //     <SaleBox img={sale.img === '' ? ImgAvatar : `${window.location.origin}/${sale.img}`} name={sale.name} tel={sale.tel} position={sale.position_id} desc={sale.desc} />
                //   </div>
                // ))
                units.map((m) =>
                <div className="row">
                  <h3>สาขา{m.unit_name}</h3>
                  <hr/>
                    {
                      salecontact.filter(f => { return f.unit_id == m.unit_id})
                      .map((sale) => (
                        <div key={sale.id} className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                          {/* <span>{sale.id}</span> */}
                          <SaleBox img={sale.img === '' ? ImgAvatar : `${window.location.origin}/${sale.img}`} name={sale.name} tel={sale.tel} position={sale.position_id} desc={sale.desc} />
                        </div>
                      ))
                    }
                  <hr/>
                </div>
                )
              : 
                <>
                  {
                    headWholeSale
                    .filter(f => { return f.unit_id == 0})
                    .map((sale) => (
                      <div key={sale.id} className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                        <SaleBox img={sale.img === '' ? ImgAvatar : `${window.location.origin}/${sale.img}`} name={sale.name} tel={sale.tel} />
                      </div>
                    ))
                  }
                  <div className="row justify-content-center">
                      {
                        wholeSale
                        .map((sale) => (
                          <div key={sale.id} className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <SaleBox img={sale.img === '' ? ImgAvatar : `${window.location.origin}/${sale.img}`} name={sale.name} tel={sale.tel} desc={sale.desc} />
                          </div>
                        ))
                      }
                  </div>
                </>
            }
          </div>
          
        </Modal.Body>
        <Modal.Footer>
          
        </Modal.Footer>
      </Modal>
    </>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;
const WrapperBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  :hover {
    opacity: 0.5;
    color:red
  }
`;
