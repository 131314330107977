import React, { useState } from "react";
import styled from "styled-components";
import ImgAvatar from '../../assets/img/personal/img_avatar1.png'

export default function SaleBox({ img, name, tel, email, desc, position }) {
    const imgSize = {
        width: 'auto',
        height: '290px',
    }
    
    const imgResize = {
        width: '376px',
        height: 'auto',
        overflow: 'hidden',
        textAlign: 'center',
    }

    return(
        <>
            <Wrapper>
                {/* <ImgBtn className="aniamte pointer" onClick={action ? () => action() : null}> */}
                <ImgDiv className="aniamte pointer">
                    <div style={imgResize}>
                        <img className="radius8" src={img} alt="project" style={imgSize}></img>
                    </div>
                    <div className="row">
                        <div className="col-4 extraBold textRight">
                            ชื่อ:
                        </div>
                        <div className="col-8">
                            {name}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 extraBold textRight">
                            เบอร์ติดต่อ:
                        </div>
                        <div className="col-8">
                            {tel}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 extraBold textRight">
                            รายละเอียด:
                        </div>
                        <div className="col-8">
                            {
                                desc ? desc : "-"
                            }
                        </div>
                    </div>
                </ImgDiv>
            </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  img {
    width: 100%;
    height: auto;
    margin: 20px 0;
  }
  h3 {
    padding-bottom: 10px;
  }
`;
const ImgDiv = styled.div`
  background-color: transparent;
  border: 0px;
  outline: none;
  padding: 0px;
  margin: 0px;
  :hover > img {
    opacity: 0.5;
  }
`;