import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";

import Bkk from '../../assets/img/logo/bkk.png'
import Region from '../../assets/img/logo/region.png'

export default function Blog() {
  return (
    <Wrapper id="blog">
      <div style={{backgroundColor:'#FFE9FD'}}>
        <div className="container whiteBg">
          <HeaderInfo>
            <h1 className="font40 extraBold">เจ้าหน้าที่การตลาด</h1>
          </HeaderInfo>
          <div className="row justify-content-center" style={{ paddingBottom:'50px'}}>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="กรุงเทพฯ และปริมณฑล"
                img={Bkk}
                //text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                tag="1"
                //author="Luke Skywalker, 2 days ago"
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="ภูมิภาค"
                img={Region}
                //text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                tag="2"
                //author="Luke Skywalker, 2 days ago"
                action={() => alert("clicked")}
              />
            </div>
          </div>

          <div className="row flexCenter">
            
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  margin-top: 10px;
`;
const HeaderInfo = styled.div`
  padding: 20px 0 0px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
  font-family: "Prompt-Medium";
  text-align: center;
`;