import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import styled from "styled-components";

import axios from "axios";

function HeaderCarousel() {
    var getUrl = window.location;
    const [carousel, setCarouset] = useState([]);

    function axiosData(url) {
      // create a promise for the axios request
      const promise = axios.get(url)
  
      // using .then, create a new promise which extracts the data
      const dataPromise = promise.then((response) => response.data)
  
      // return it
      return dataPromise
    }

    useEffect(() => {
      //console.log(Date.now())
      axiosData(`data/DataHeadCarousel.json`)
                .then(response => setCarouset(response.data))
                .catch(err => console.log(err));
    },[])
    return (
        <Carousel>
          {
            carousel.map((c) => 
            <Carousel.Item interval={1000}>
                <Img className="radius8" src={c.img} alt="office" style={{zIndex: 9, width:'100%'}} />
            </Carousel.Item>
            )
          }
            {/* <Carousel.Item interval={1000}>
                <Img className="radius8" src={`${getUrl}img/news/20231225/kidday.jpg`} alt="office" style={{zIndex: 9, width:'100%'}} />
            </Carousel.Item>
            <Carousel.Item interval={1000}>
                <Img className="radius8" src={`${getUrl}img/news/20231225/newyearsale.jpg`} alt="office" style={{zIndex: 9, width:'100%'}} />
            </Carousel.Item>
            <Carousel.Item interval={1000}>
                <Img className="radius8" src={`${getUrl}img/slides/lazada.jpg`} alt="office" style={{zIndex: 9, width:'100%'}} />
            </Carousel.Item>
            <Carousel.Item interval={1000}>
                <Img className="radius8" src={`${getUrl}img/slides/shopee.jpg`} alt="office" style={{zIndex: 9, width:'100%'}} />
            </Carousel.Item>
            <Carousel.Item interval={1000}>
                <Img className="radius8" src={`${getUrl}img/slides/sspmall.jpg`} alt="office" style={{zIndex: 9, width:'100%'}} />
            </Carousel.Item> */}
        </Carousel>
    )
}

const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: 607px;
  }
`;

export default HeaderCarousel;