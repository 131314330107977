const getItem = (itemName) => localStorage.getItem(itemName);

const saveItem = (itemName, itemValue) => {
    localStorage.setItem(itemName, itemValue);
};

localStorage.setItem('currentUrl', 'http://localhost/')
export const saveCurrentURL = (url) => {
    saveItem('currentURL', 'http://localhost/');
}

export const loadCurrentURL = () => getItem('currentURL');

export const clearState = () => {
    localStorage.clear();
};